body,html,#root,.App{
  height:100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#toast-root{
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 340px;
  padding: 10px 4px;
  position: fixed;
  display: flex;
  flex-direction:column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 310;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

button, input, select, textarea {
  outline: none;
  border: 0;
}